/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreateLegalProcessRequest } from '../model/models';
import { FilterViewPagination } from '../model/models';
import { InlineResponse200 } from '../model/models';
import { InlineResponse2001 } from '../model/models';
import { LegalProcess } from '../model/models';
import { LegalProcessPayload } from '../model/models';
import { ListProcessesResponse } from '../model/models';
import { ProcessPermission } from '../model/models';
import { ProcessTrigger } from '../model/models';
import { Variable } from '../model/models';
import { VariableQueryResponse } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AddProcessTriggerRequestParams {
    /** Id of process to get */
    processid: string;
    processTrigger: ProcessTrigger;
    xAuthToken?: string;
}

export interface AddProcessVariablesRequestParams {
    /** ID of process for which to get variables */
    processid: string;
    variable: Array<Variable>;
    xAuthToken?: string;
    /** The realm for which to obtain the process */
    realm?: string;
}

export interface CreateProcessRequestParams {
    createLegalProcessRequest: CreateLegalProcessRequest;
    xAuthToken?: string;
}

export interface DeleteProcessRequestParams {
    /** Id of process to get */
    processid: string;
    /** Realm in which to search for the process */
    realm?: string;
    xAuthToken?: string;
    preview?: boolean;
}

export interface DeleteProcessTriggerRequestParams {
    /** Id of process to get */
    processid: string;
    /** Id of trigger to delete */
    triggerid: string;
    xAuthToken?: string;
}

export interface GetProcessRequestParams {
    /** Id of process to get */
    processid: string;
    /** Realm in which to search for the process */
    realm?: string;
    xAuthToken?: string;
    preview?: boolean;
}

export interface GetProcessBySlugRequestParams {
    /** Slug of process to get */
    slug: string;
    /** Realm in which to search for the process */
    realm?: string;
    xAuthToken?: string;
    preview?: boolean;
}

export interface GetProcessPermissionsRequestParams {
    /** Id of process */
    processid: string;
    xAuthToken?: string;
}

export interface GetProcessTriggersRequestParams {
    /** Id of process to get */
    processid: string;
    xAuthToken?: string;
}

export interface GetProcessVariablesRequestParams {
    /** ID of process for which to get variables */
    processid: string;
    filterViewPagination: FilterViewPagination;
    xAuthToken?: string;
    /** The realm for which to obtain the process */
    realm?: string;
}

export interface GetRealmForLegacyProcessRequestParams {
    /** Id of process to get realm for */
    processid: string;
}

export interface ListProcessesRequestParams {
    filterViewPagination: FilterViewPagination;
    xAuthToken?: string;
    includeTemplate?: boolean;
}

export interface RemoveProcessPermissionsRequestParams {
    /** Id of process */
    processid: string;
    /** Id of lawyer */
    lawyerid: string;
    xAuthToken?: string;
}

export interface RemoveProcessVariablesRequestParams {
    /** ID of process to get */
    processid: string;
    requestBody: Array<string>;
    xAuthToken?: string;
}

export interface RemoveUserFromProcessRequestParams {
    /** ID of client to assign process to */
    userid: string;
    /** ID of process to assign user to */
    processid: string;
    xAuthToken?: string;
}

export interface SetProcessRequestParams {
    /** Id of process to get */
    processid: string;
    legalProcessPayload: LegalProcessPayload;
    /** Realm in which to search for the process */
    realm?: string;
    xAuthToken?: string;
    preview?: boolean;
}

export interface SetProcessPermissionsRequestParams {
    /** Id of process */
    processid: string;
    processPermission: ProcessPermission;
    xAuthToken?: string;
}

export interface UpdateProcessPermissionsRequestParams {
    /** Id of process */
    processid: string;
    processPermission: ProcessPermission;
    xAuthToken?: string;
}


@Injectable({
  providedIn: 'root'
})
export class LegalProcessService {

    protected basePath = 'https://staging-api.silberfluss.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add a trigger to this process
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProcessTrigger(requestParameters: AddProcessTriggerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ProcessTrigger>>;
    public addProcessTrigger(requestParameters: AddProcessTriggerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ProcessTrigger>>>;
    public addProcessTrigger(requestParameters: AddProcessTriggerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ProcessTrigger>>>;
    public addProcessTrigger(requestParameters: AddProcessTriggerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling addProcessTrigger.');
        }
        const processTrigger = requestParameters.processTrigger;
        if (processTrigger === null || processTrigger === undefined) {
            throw new Error('Required parameter processTrigger was null or undefined when calling addProcessTrigger.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<ProcessTrigger>>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}/triggers`,
            processTrigger,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add or Update variables of existing process
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProcessVariables(requestParameters: AddProcessVariablesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public addProcessVariables(requestParameters: AddProcessVariablesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public addProcessVariables(requestParameters: AddProcessVariablesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public addProcessVariables(requestParameters: AddProcessVariablesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling addProcessVariables.');
        }
        const variable = requestParameters.variable;
        if (variable === null || variable === undefined) {
            throw new Error('Required parameter variable was null or undefined when calling addProcessVariables.');
        }
        const xAuthToken = requestParameters.xAuthToken;
        const realm = requestParameters.realm;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}/variables`,
            variable,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new process
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProcess(requestParameters: CreateProcessRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public createProcess(requestParameters: CreateProcessRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public createProcess(requestParameters: CreateProcessRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public createProcess(requestParameters: CreateProcessRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const createLegalProcessRequest = requestParameters.createLegalProcessRequest;
        if (createLegalProcessRequest === null || createLegalProcessRequest === undefined) {
            throw new Error('Required parameter createLegalProcessRequest was null or undefined when calling createProcess.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/legal-process/new/`,
            createLegalProcessRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete process with given id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProcess(requestParameters: DeleteProcessRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteProcess(requestParameters: DeleteProcessRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteProcess(requestParameters: DeleteProcessRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteProcess(requestParameters: DeleteProcessRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling deleteProcess.');
        }
        const realm = requestParameters.realm;
        const xAuthToken = requestParameters.xAuthToken;
        const preview = requestParameters.preview;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }
        if (preview !== undefined && preview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>preview, 'preview');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete process trigger with given id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProcessTrigger(requestParameters: DeleteProcessTriggerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ProcessTrigger>>;
    public deleteProcessTrigger(requestParameters: DeleteProcessTriggerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ProcessTrigger>>>;
    public deleteProcessTrigger(requestParameters: DeleteProcessTriggerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ProcessTrigger>>>;
    public deleteProcessTrigger(requestParameters: DeleteProcessTriggerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling deleteProcessTrigger.');
        }
        const triggerid = requestParameters.triggerid;
        if (triggerid === null || triggerid === undefined) {
            throw new Error('Required parameter triggerid was null or undefined when calling deleteProcessTrigger.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<Array<ProcessTrigger>>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}/triggers/${encodeURIComponent(String(triggerid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get process by id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcess(requestParameters: GetProcessRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LegalProcess>;
    public getProcess(requestParameters: GetProcessRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LegalProcess>>;
    public getProcess(requestParameters: GetProcessRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LegalProcess>>;
    public getProcess(requestParameters: GetProcessRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling getProcess.');
        }
        const realm = requestParameters.realm;
        const xAuthToken = requestParameters.xAuthToken;
        const preview = requestParameters.preview;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }
        if (preview !== undefined && preview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>preview, 'preview');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LegalProcess>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get process by slug
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessBySlug(requestParameters: GetProcessBySlugRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LegalProcess>;
    public getProcessBySlug(requestParameters: GetProcessBySlugRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LegalProcess>>;
    public getProcessBySlug(requestParameters: GetProcessBySlugRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LegalProcess>>;
    public getProcessBySlug(requestParameters: GetProcessBySlugRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const slug = requestParameters.slug;
        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling getProcessBySlug.');
        }
        const realm = requestParameters.realm;
        const xAuthToken = requestParameters.xAuthToken;
        const preview = requestParameters.preview;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }
        if (preview !== undefined && preview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>preview, 'preview');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LegalProcess>(`${this.configuration.basePath}/legal-process/slug/${encodeURIComponent(String(slug))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return process permissions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessPermissions(requestParameters: GetProcessPermissionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2001>;
    public getProcessPermissions(requestParameters: GetProcessPermissionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2001>>;
    public getProcessPermissions(requestParameters: GetProcessPermissionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2001>>;
    public getProcessPermissions(requestParameters: GetProcessPermissionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling getProcessPermissions.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InlineResponse2001>(`${this.configuration.basePath}/legal-process/permissions/${encodeURIComponent(String(processid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get process triggers
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessTriggers(requestParameters: GetProcessTriggersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ProcessTrigger>>;
    public getProcessTriggers(requestParameters: GetProcessTriggersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ProcessTrigger>>>;
    public getProcessTriggers(requestParameters: GetProcessTriggersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ProcessTrigger>>>;
    public getProcessTriggers(requestParameters: GetProcessTriggersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling getProcessTriggers.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ProcessTrigger>>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}/triggers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get variables for a specific process by id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessVariables(requestParameters: GetProcessVariablesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VariableQueryResponse>;
    public getProcessVariables(requestParameters: GetProcessVariablesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VariableQueryResponse>>;
    public getProcessVariables(requestParameters: GetProcessVariablesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VariableQueryResponse>>;
    public getProcessVariables(requestParameters: GetProcessVariablesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling getProcessVariables.');
        }
        const filterViewPagination = requestParameters.filterViewPagination;
        if (filterViewPagination === null || filterViewPagination === undefined) {
            throw new Error('Required parameter filterViewPagination was null or undefined when calling getProcessVariables.');
        }
        const xAuthToken = requestParameters.xAuthToken;
        const realm = requestParameters.realm;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<VariableQueryResponse>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}/variables`,
            filterViewPagination,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get realm for legacy process (i.e. without known realm)
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRealmForLegacyProcess(requestParameters: GetRealmForLegacyProcessRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200>;
    public getRealmForLegacyProcess(requestParameters: GetRealmForLegacyProcessRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200>>;
    public getRealmForLegacyProcess(requestParameters: GetRealmForLegacyProcessRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200>>;
    public getRealmForLegacyProcess(requestParameters: GetRealmForLegacyProcessRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling getRealmForLegacyProcess.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InlineResponse200>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}/realm`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all processes valid for this auth token with pagination
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listProcesses(requestParameters: ListProcessesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ListProcessesResponse>;
    public listProcesses(requestParameters: ListProcessesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ListProcessesResponse>>;
    public listProcesses(requestParameters: ListProcessesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ListProcessesResponse>>;
    public listProcesses(requestParameters: ListProcessesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const filterViewPagination = requestParameters.filterViewPagination;
        if (filterViewPagination === null || filterViewPagination === undefined) {
            throw new Error('Required parameter filterViewPagination was null or undefined when calling listProcesses.');
        }
        const xAuthToken = requestParameters.xAuthToken;
        const includeTemplate = requestParameters.includeTemplate;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (includeTemplate !== undefined && includeTemplate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeTemplate, 'include_template');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ListProcessesResponse>(`${this.configuration.basePath}/legal-process/list`,
            filterViewPagination,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove appropriate process permissions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeProcessPermissions(requestParameters: RemoveProcessPermissionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public removeProcessPermissions(requestParameters: RemoveProcessPermissionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public removeProcessPermissions(requestParameters: RemoveProcessPermissionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public removeProcessPermissions(requestParameters: RemoveProcessPermissionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling removeProcessPermissions.');
        }
        const lawyerid = requestParameters.lawyerid;
        if (lawyerid === null || lawyerid === undefined) {
            throw new Error('Required parameter lawyerid was null or undefined when calling removeProcessPermissions.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/legal-process/permissions/${encodeURIComponent(String(processid))}/lawyer/${encodeURIComponent(String(lawyerid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete one or more process variables
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeProcessVariables(requestParameters: RemoveProcessVariablesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public removeProcessVariables(requestParameters: RemoveProcessVariablesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public removeProcessVariables(requestParameters: RemoveProcessVariablesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public removeProcessVariables(requestParameters: RemoveProcessVariablesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling removeProcessVariables.');
        }
        const requestBody = requestParameters.requestBody;
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling removeProcessVariables.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}/variables/delete`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove user from process
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeUserFromProcess(requestParameters: RemoveUserFromProcessRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public removeUserFromProcess(requestParameters: RemoveUserFromProcessRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public removeUserFromProcess(requestParameters: RemoveUserFromProcessRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public removeUserFromProcess(requestParameters: RemoveUserFromProcessRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const userid = requestParameters.userid;
        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling removeUserFromProcess.');
        }
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling removeUserFromProcess.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(userid))}/assign/${encodeURIComponent(String(processid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set process by id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setProcess(requestParameters: SetProcessRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public setProcess(requestParameters: SetProcessRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public setProcess(requestParameters: SetProcessRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public setProcess(requestParameters: SetProcessRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling setProcess.');
        }
        const legalProcessPayload = requestParameters.legalProcessPayload;
        if (legalProcessPayload === null || legalProcessPayload === undefined) {
            throw new Error('Required parameter legalProcessPayload was null or undefined when calling setProcess.');
        }
        const realm = requestParameters.realm;
        const xAuthToken = requestParameters.xAuthToken;
        const preview = requestParameters.preview;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }
        if (preview !== undefined && preview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>preview, 'preview');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/legal-process/${encodeURIComponent(String(processid))}`,
            legalProcessPayload,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set appropriate process permissions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setProcessPermissions(requestParameters: SetProcessPermissionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public setProcessPermissions(requestParameters: SetProcessPermissionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public setProcessPermissions(requestParameters: SetProcessPermissionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public setProcessPermissions(requestParameters: SetProcessPermissionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling setProcessPermissions.');
        }
        const processPermission = requestParameters.processPermission;
        if (processPermission === null || processPermission === undefined) {
            throw new Error('Required parameter processPermission was null or undefined when calling setProcessPermissions.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/legal-process/permissions/${encodeURIComponent(String(processid))}`,
            processPermission,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update appropriate process permissions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProcessPermissions(requestParameters: UpdateProcessPermissionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateProcessPermissions(requestParameters: UpdateProcessPermissionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateProcessPermissions(requestParameters: UpdateProcessPermissionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateProcessPermissions(requestParameters: UpdateProcessPermissionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const processid = requestParameters.processid;
        if (processid === null || processid === undefined) {
            throw new Error('Required parameter processid was null or undefined when calling updateProcessPermissions.');
        }
        const processPermission = requestParameters.processPermission;
        if (processPermission === null || processPermission === undefined) {
            throw new Error('Required parameter processPermission was null or undefined when calling updateProcessPermissions.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/legal-process/permissions/${encodeURIComponent(String(processid))}`,
            processPermission,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
